


































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SnackBar extends Vue {
  @Prop({ required: true }) snackbar: boolean = false;
  @Prop({ required: false, default: true }) top: boolean = true;
  @Prop({ required: false, default: true }) right: boolean = true;
  @Prop({ required: false, default: false }) bottom: boolean = false;
  @Prop({ required: true }) text!: string;
  @Prop({ required: true }) timeout!: number;
  @Prop({ required: false, default: '#000' }) status!: string;
  @Prop({ required: false, default: false }) downloading!: boolean;

  @Emit('close')
  close(): boolean {
    return false;
  }
}
